import React, { useState } from 'react';
import Badge from 'react-bootstrap/Badge';
import Alert from 'react-bootstrap/Alert';
import FormInputField from '../FormInputField/FormInputField';
import Button from '../Button';
import * as styles from './Split.module.css';
import Chip from '../Chip/Chip';

const Split = (props) => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);


  const addEmailHandler = (e) => {
    e.preventDefault();
    setEmail('');
    setSubmitted(true);
    console.log('Subscribe this email: ', email);
  };

  const {
    image,
    alt,
    title,
    description,
    ctaText,
    cta,
    bgColor,
    badge,
    emailSignUp,
  } = props;
  return (
    <div className={styles.root}>
      <div
        className={styles.contentContainer}
        style={{ backgroundColor: bgColor }}
      >
        <div className={styles.detailContainer}>
          <h5>{badge && <Badge bg="primary">{badge}</Badge>}</h5>
          <h4>{title} </h4>

          <p>{description}</p>
          {ctaText && (
            <Button className={styles.button} level={'primary'} onClick={cta}>
              {ctaText}
            </Button>
          )}
          {emailSignUp && (
            <form
              className={styles.form}
              name={'online-consultation-sign-up'}
              onSubmit={(e) => addEmailHandler(e)}
              method={'POST'}
              data-netlify-honeypot="bot-field"
              data-netlify="true"
              action="/services"
            >
              <input type="hidden" name="form-name" value="contact" />
              <FormInputField
                icon={'arrow'}
                id={'online-consultation-id'}
                placeholder={'Email'}
                type={'email'}
                value={email}
                handleChange={(_, e) => setEmail(e)}
                onSubmit={(e) => addEmailHandler(e)}
              />
              {submitted && (
                <div>
                  <Alert variant='success'>
                    Thanks for submitting your email, we'll let you know when there's availability!
                  </Alert>
                </div>
              )}
            </form>
          )}
        </div>
      </div>
      <div className={styles.imageContainer}>
        <img src={image} alt={alt}></img>
      </div>
    </div>
  );
};

export default Split;
