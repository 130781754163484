import React, { useRef } from 'react';

import Container from '../components/Container';
import Hero from '../components/Hero';
import Banner from '../components/Banner';
import ThemeLink from '../components/ThemeLink';
import Layout from '../components/Layout/Layout';
import Split from '../components/Split';
import { navigate } from 'gatsby';

import * as styles from './services.module.css';
const ServicesPage = (props) => {
  let historyRef = useRef();
  let valuesRef = useRef();
  let sustainabilityRef = useRef();

  const handleScroll = (elementReference) => {
    if (elementReference) {
      window.scrollTo({
        behavior: 'smooth',
        top: elementReference.current.offsetTop - 280,
      });
    }
  };

  return (
    <Layout disablePaddingBottom title={'Services'}>
      <div className={styles.root}>
        {/* Hero Container */}
        <Banner
          maxWidth={'900px'}
          height={'260px'}
          bgImage={'/background/mountains_bg.jpg'}
          name={`Services \n `}
          color={'var(--standard-white)'}
        />

        {/* <div className={styles.navContainer}>
          <ThemeLink onClick={() => handleScroll(historyRef)} to={'#history'}>
            History
          </ThemeLink>
          <ThemeLink onClick={() => handleScroll(valuesRef)} to={'#values'}>
            Values
          </ThemeLink>
          <ThemeLink
            onClick={() => handleScroll(sustainabilityRef)}
            to={'#sustainability'}
          >
            Sustainability
          </ThemeLink>
        </div> */}

        {/* <Container size={'large'} spacing={'min'}>
          <div className={styles.detailContainer} ref={historyRef}>
            <p>
              Founded in 1860, Sydney is an innovative British brand with a
              contemporary edge. We make timeless everyday luxury clothing.
            </p>
            <br />
            <br />
            <p>
              We created some of the world's first T-shirts and spent decades
              perfecting the feel of the cotton. Today we are the only brand
              that makes T-shirts in its own factory in the UK. And we do this
              in the same factory we have occupied since 1937.
            </p>
          </div>
        </Container> */}
        <br />
        <br />
        <Container size={'large'} spacing={'min'}>
          <div className={styles.content}>
            <h3>Shen Clinic Services</h3>
            <div ref={valuesRef}>
              <p>
                We offer a variety of services at Shen Clinic to help you on
                your path to wellness. Feel free to call us to learn more about
                each of them or make an appointment.
              </p>
            </div>
          </div>
        </Container>
        <Split
          image={'/shenclinic/consultation.jpg'}
          alt={'Dr. Shen TCM Consultation'}
          title={'Consultation'}
          description={
            'Dr. Shen will do a Traditional Chinese Medicine diagnosis to determine the best course of treatment for you. He will then prescribe a customized herbal formula and/or acupuncture treatment plan.'
          }
          bgColor={'#f2faf3'}
          ctaText={'Call Us to Make an Appointment'}
          cta={() => navigate('/support/#contact')}
        />
        <Split
          image={'/infertility/pregnant.jpg'}
          alt={'Dr. Shen infertility pregnancy'}
          title={'Infertility Treatments'}
          description={
            "Dr. Shen has specialized in infertility and women's health and offers a variety of treatments to help you conceive naturally or in conjunction with IVF."
          }
          ctaText={'Learn more about Infertility treatments'}
          cta={() => navigate('/womenshealth')}
          bgColor={'#f2faf3'}
        />

        <Split
          image={'/shenclinic/Acupuncture3.jpg'}
          alt={'Dr. Shen doing acupuncture'}
          title={'Acupuncture'}
          description={
            'Acupuncture is a safe and effective treatment for many conditions. It is a minimally invasive procedure that involves inserting thin needles into specific points on the body to stimulate the body’s natural healing response.'
          }
          ctaText={'Call Us to Make an Appointment'}
          cta={() => navigate('/support/#contact')}
          bgColor={'#f2faf3'}
        />

        <Split
          image={'/shenclinic/Cupping.jpg'}
          alt={'Dr. Shen doing cupping'}
          title={'Cupping Therapies'}
          description={
            'Cupping is a therapy that uses suction to stimulate blood flow and promote healing. It is used to treat pain, inflammation, and other conditions.'
          }
          ctaText={'Call Us to Make an Appointment'}
          cta={() => navigate('/support/#contact')}
          bgColor={'#f2faf3'}
        />

        <Split
          image={'/shenclinic/consultation.jpg'}
          alt={'Dr. Shen Online consultation'}
          title={'Online Consultation'}
          badge={'Coming Soon!'}
          description={
            'Online consultations with Dr. Shen will available online soon. Add your email below to get notified when openings are available.'
          }
          bgColor={'#f2faf3'}
          emailSignUp={true}
        />
      </div>
    </Layout>
  );
};

export default ServicesPage;
